@import
  "minima/skins/classic",
  "minima/initialize";

.site-title {
  @include relative-font-size(1.25);
}

em {
  display: block;
  text-align: center;
  font-style: normal;
  font-size: 80%;
  padding: 0;
  color: #6d6c6c;
}


.screenshot {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #ddd; /* 灰色边框 */
  border-radius: 10px; /* 圆角边框 */
  padding: 5px; /* 内边距，避免边框紧贴截图 */
  background-color: white; /* 背景色让截图更突出 */
  width: 90%; /* 宽度100% */
}


.chinese-post {
  font-family: "Noto Serif SC", "Microsoft YaHei", "PingFang SC", sans-serif;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  text-align: justify;
  
}

.post-title {
  font-family: 'Noto Serif SC', serif;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: left;
  color: #333;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.chinese-post-content p{
  text-indent: 2em;
  line-height: 1.8em;
}

.word-count{
  font-size: 0.8rem;
  color: #6d6c6c;
  text-align: right;
}